<template>
  <v-data-table
    :headers="headers"
    :loading="!!!buildingComplexes"
    :items="buildingComplexes"
    :items-per-page="10"
    :server-items-length="meta.total"
    :footer-props="footerProps"
    :options.sync="localOptions"
    :sort-by="sortBy"
    sort-desc
  >
    <template v-slot:item.status="{ item }">
      <v-chip :color="item.status_color" text-color="white">
        {{ item.status }}
      </v-chip>
    </template>

    <template v-slot:item.action="{ item }">
      <div class="text-center">
        <v-list-item
          @click="$emit('show', item)"
          target="_blank"
          :to="{
            name: 'building_complexes.show',
            params: { id: item.id },
          }"
          link
        >
          <v-list-item-title>
            <v-icon class="mr-1" small>mdi-eye</v-icon>
            {{ $t('ITEM.VIEW') }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import i18nRefreshData from '@/mixins/i18nRefreshData';

export default {
  name: 'BuildingComplexesList',

  mixins: [i18nRefreshData],

  props: {
    buildingComplexes: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => ({
        total: 0,
      }),
    },
    options: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    unwatch: null,
    localOptions: {},
    sortBy: 'id',
    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    params() {
      const options = this.localOptions;

      return {
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[options.sortBy.length - 1],
        order_by: options.sortDesc[options.sortDesc.length - 1]
          ? 'desc'
          : 'asc',
      };
    },
    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('ITEM.NAME'),
          value: 'name',
        },
        {
          text: this.$t('PROFILE.ZIP'),
          value: 'zip',
        },
        {
          text: this.$t('PROFILE.CITY'),
          value: 'city',
        },
        {
          text: this.$t('ITEM.TYPE'),
          value: 'type',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.BUSINESS_CUSTOMER_NAME'),
          value: 'business_customer',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY'),
          value: 'management_company',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.CONCIERGE'),
          value: 'concierge',
        },
        {
          text: this.$t('PROFILE.STATUS'),
          value: 'status',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          align: 'center',
        },
      ];
    },
  },

  watch: {
    localOptions: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
    options: {
      handler() {
        this.localOptions.page = 1;
        this.refreshData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.$emit('refresh', Object.assign({}, this.options, this.params));
    },
  },
};
</script>
