<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{
        $t('BUILDING_COMPLEXES.BUILDING_COMPLEXES_MANAGEMENT')
      }}</v-toolbar-title>
    </v-toolbar>

    <v-sheet class="mt-5 pa-5" tile>
      <v-row>
        <v-col cols="12">
          <building-complexes-list-filters @setRequest="setRequest" />
        </v-col>
        <v-col cols="12">
          <building-complexes-list
            :building-complexes="buildingComplexes"
            :options="options"
            :meta="meta"
            @refresh="getDataBuildingComplexes"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { BuildingComplexesService } from '@/services/building-complexes.service';
import BuildingComplexesList from '../components/BuildingComplexesList.vue';
import BuildingComplexesListFilters from '../components/BuildingComplexesListFilters.vue';

export default {
  name: 'BuildingComplexes',

  components: {
    BuildingComplexesList,
    BuildingComplexesListFilters,
  },

  data() {
    return {
      buildingComplexes: [],
      options: null,
      meta: {},
    };
  },

  methods: {
    setRequest(v) {
      this.options = v;
    },

    getDataBuildingComplexes(params) {
      BuildingComplexesService.getList(params).then(({ data }) => {
        this.buildingComplexes = data.data;
        this.meta = data.meta;
      });
    },
  },
};
</script>
