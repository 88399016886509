<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :multiple="multiple"
    :search-input.sync="search"
    :no-data-text="$t('MESSAGE.NO_DATA_FILTER_TEXT')"
    clearable
    cache-items
    v-bind="$attrs"
    v-on="listeners"
  >
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from 'lodash';
import i18nRefreshData from '@/mixins/i18nRefreshData';

export default {
  name: 'BaseAutocompleteFilter',

  mixins: [i18nRefreshData],

  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    delay: {
      type: Number,
      default: 500,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      items: [],
      debounced: null,
      searchCache: new Set(),
    };
  },

  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        change: [this.clearSearch, this.emitter],
        'click:clear': this.clearFilter,
      });
    },
  },

  watch: {
    search(newVal, oldVal) {
      if (newVal && newVal !== oldVal && newVal.trim() !== this.value) {
        this.debounced();
      }
    },
  },

  created() {
    this.debounced = debounce(this.refreshData, this.delay);
  },

  methods: {
    clearSearch() {
      this.search = '';
    },

    clearFilter() {
      this.emitter(this.multiple ? [] : '');
    },

    emitter(data) {
      this.$emit('input', data);
      this.$emit('change', data);
    },

    refreshData() {
      if (this.search && !this.searchCache.has(this.search)) {
        this.searchCache.add(this.search);
        this.getData();
      }
    },

    getData() {
      throw new Error('"getData" function is not implemented');
    },
  },
};
</script>
