var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"loading":!!!_vm.buildingComplexes,"items":_vm.buildingComplexes,"items-per-page":10,"server-items-length":_vm.meta.total,"footer-props":_vm.footerProps,"options":_vm.localOptions,"sort-by":_vm.sortBy,"sort-desc":""},on:{"update:options":function($event){_vm.localOptions=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status_color,"text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-list-item',{attrs:{"target":"_blank","to":{
          name: 'building_complexes.show',
          params: { id: item.id },
        },"link":""},on:{"click":function($event){return _vm.$emit('show', item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(_vm.$t('ITEM.VIEW'))+" ")],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }