<template>
  <v-select
    :value="value"
    :items="items"
    :multiple="multiple"
    clearable
    v-bind="$attrs"
    v-on="listeners"
  >
    <template #selection="data">
      <v-chip
        text-color="white"
        v-bind="data.attrs"
        :input-value="data.selected"
        :color="data.item.color"
        close
        @click="data.select"
        @click:close="removeItem(data.index)"
      >
        {{ data.item.name }}
      </v-chip>
    </template>

    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
import { BuildingComplexesService } from '@/services/building-complexes.service';
import BaseSelectFilter from './BaseSelectFilter';

export default {
  name: 'StatusesFilter',

  extends: BaseSelectFilter,

  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getData() {
      BuildingComplexesService.getStatuses().then(({ data }) => {
        this.items = data;
      });
    },

    removeItem(index) {
      this.emitter(
        this.multiple ? this.value.filter((_, i) => i !== index) : null
      );
    },
  },
};
</script>
