<template>
  <v-select
    :value="value"
    :items="items"
    clearable
    v-bind="$attrs"
    v-on="listeners"
  >
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
import i18nRefreshData from '@/mixins/i18nRefreshData';

export default {
  name: 'BaseSelectFilter',

  mixins: [i18nRefreshData],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
  },

  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        change: this.emitter,
      });
    },
  },

  data() {
    return {
      items: [],
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    refreshData() {
      this.getData();
    },

    emitter(data) {
      this.$emit('input', data);
      this.$emit('change', data);
    },

    getData() {
      throw new Error('"getData" function is not implemented');
    },
  },
};
</script>
