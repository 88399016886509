<script>
import { BuildingComplexesService } from '@/services/building-complexes.service';
import BaseSelectFilter from './BaseSelectFilter';

export default {
  name: 'TypesFilter',

  extends: BaseSelectFilter,

  methods: {
    getData() {
      BuildingComplexesService.getTypes().then(({ data }) => {
        this.items = data;
      });
    },
  },
};
</script>
