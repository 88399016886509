<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12" md="10">
        <v-row align="baseline">
          <v-col cols="6" md="3">
            <v-text-field v-model="filters.name" :label="$t('ITEM.NAME')" />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field v-model="filters.zip" :label="$t('PROFILE.ZIP')" />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field v-model="filters.city" :label="$t('PROFILE.CITY')" />
          </v-col>
          <v-col cols="6" md="3">
            <types-filter
              v-model="filters.type"
              item-value="id"
              item-text="name"
              :label="$t('ITEM.TYPE')"
              chips
              multiple
              clearable
              small-chips
              deletable-chips
            />
          </v-col>
        </v-row>
        <v-row class="mt-0" align="baseline">
          <v-col cols="6" md="3">
            <business-customers-filter
              v-model="filters.business_customer"
              item-text="name"
              item-value="name"
              :label="$t('BUILDING_COMPLEXES.BUSINESS_CUSTOMER')"
            />
          </v-col>
          <v-col cols="6" md="3">
            <concierges-filter
              v-model="filters.concierge"
              item-text="full_name"
              item-value="full_name"
              :label="$t('BUILDING_COMPLEXES.CONCIERGE')"
            />
          </v-col>
          <v-col cols="6" md="3">
            <management-companies-filter
              v-model="filters.management_company"
              item-text="name"
              item-value="name"
              :label="$t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY')"
            />
          </v-col>
          <v-col cols="6" md="3">
            <statuses-filter
              v-model="filters.status"
              item-value="id"
              item-text="name"
              :label="$t('ITEM.STATUS')"
              chips
              multiple
              clearable
              small-chips
              deletable-chips
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="2"
        align-self="center"
        class="d-flex flex-wrap justify-md-space-between"
      >
        <v-btn class="mb-1 mr-1" color="grey" depressed outlined @click="reset">
          {{ $t('BUTTON.CLEAR_FILTER') }}
        </v-btn>
        <v-btn
          class="mb-1"
          type="submit"
          color="primary"
          depressed
          @click.prevent="refreshData"
        >
          {{ $t('BUTTON.FIND') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import TypesFilter from './filters/TypesFilter';
import StatusesFilter from './filters/StatusesFilter';
import ConciergesFilter from './filters/ConciergesFilter';
import BusinessCustomersFilter from './filters/BusinessCustomersFilter';
import ManagementCompaniesFilter from './filters/ManagementCompaniesFilter';

export default {
  name: 'BuildingComplexesListFilters',

  components: {
    TypesFilter,
    StatusesFilter,
    ConciergesFilter,
    BusinessCustomersFilter,
    ManagementCompaniesFilter,
  },

  data() {
    return {
      filters: {},
    };
  },

  methods: {
    refreshData() {
      this.$emit('setRequest', { ...this.filters });
    },

    reset() {
      this.$refs.form.reset();
      this.filters = {};
      this.refreshData();
    },
  },
};
</script>
