<script>
import { BuildingComplexesService } from '@/services/building-complexes.service';
import BaseAutocompleteFilter from './BaseAutocompleteFilter';

export default {
  name: 'ManagementCompaniesFilter',

  extends: BaseAutocompleteFilter,

  methods: {
    getData() {
      BuildingComplexesService.getFilteredManagementCompanies({
        search: this.search,
      }).then(({ data }) => {
        this.items = data;
      });
    },
  },
};
</script>
